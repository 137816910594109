<template>
  <div class="content ledger-list">
    <!-- <div class="form-content">
      <a-row :gutter="16" style="margin-top: 15px">
        <a-col :span="6">
          <a-input
            allow-clear
            v-model.trim="formData.voucehrSn"
            :placeholder="$t('请输入凭证单据号')"
          />
        </a-col>
        <a-col :span="6">
          <a-input
            allow-clear
            v-model.trim="formData.voucehrNo"
            :placeholder="$t('请输入凭证号')"
          />
        </a-col>
        <a-col :span="6">
          <CommonSelect
            :list="journal_voucher_type"
            :code.sync="formData.voucherType"
            :placeholder="$t('凭证字')"
          />
        </a-col>
        <a-col :span="6">
          <a-button @click="searchHandle(1)" type="primary">
            <i class="iconfont" style="margin-right: 5px">&#xe647;</i>
            {{ $t(`查询`) }}
          </a-button>
          <a-button @click="reset">
            <a-icon type="redo" />
            {{ $t(`重置`) }}
          </a-button>
        </a-col>
      </a-row>
    </div> -->
    <CommonTable
      ref="CommonTableRef"
      :columns.sync="columns"
      :loading="loading"
      :dataSource="tableData"
      :scroll="{ x: 1500, y: scrollHeight }"
      :selectedRowKeys.sync="selectedRowKeys"
      :total="total"
      rowKey="id"
      @select="onSelectChange"
      @search="getList"
    >
      <template v-slot:debitAmount="{ record }">
        <PriceInput :value.sync="record.debitAmount" :showTxt="true" :currency="record.currency" />
      </template>
      <template v-slot:creditAmount="{ record }">
        <PriceInput :value.sync="record.creditAmount" :showTxt="true" :currency="record.currency" />
      </template>
    </CommonTable>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import http from '@/service/axios'
import api from '@/service/api'
import CommonTable from '@/component/commonTable'
import { convertKeysToCamelCase } from '@/common'
export default {
  name: 'LedgerList',
  components: { CommonTable },
  data() {
    return {
      docType: 'Ledger',
      formData: {},
      tableData: [],
      selectedRowKeys: [],
      selectedRows: [],
      total: 0,
      loading: false,

      columns: [
        {
          title: this.$t('科目编码'),
          dataIndex: 'accountCode',
        },
        {
          title: this.$t('科目名称'),
          dataIndex: 'accountName',
        },
        {
          title: this.$t('会计期间'),
          dataIndex: 'accountingPeriodName',
        },
        {
          title: this.$t('记账日期'),
          dataIndex: 'accountingDate',
          customRender: (text) => text?.split(' ')[0],
        },
        {
          title: this.$t('业务日期'),
          dataIndex: 'bizDate',
          customRender: (text) => text?.split(' ')[0],
        },
        {
          title: this.$t('凭证字号'),
          dataIndex: 'voucherNo',
        },
        {
          title: this.$t('摘要'),
          dataIndex: 'summary',
          width: 300,
        },
        {
          title: this.$t('核算维度'),
          dataIndex: 'dimensionListName',
          width: 250,
        },
        {
          title: this.$t('借方'),
          dataIndex: 'debitAmount',
          scopedSlots: {
            customRender: 'debitAmount',
          },
          width: 120,
        },
        {
          title: this.$t('贷方'),
          dataIndex: 'creditAmount',
          scopedSlots: {
            customRender: 'creditAmount',
          },
          width: 120,
        },
      ],
    }
  },
  activated() {
    if (!this.$route.query.accountingDimensionCode) {
      this.columns.splice(-3, 1)
    }
    this.searchHandle()
  },
  computed: {
    scrollHeight() {
      return window.innerHeight - 270
    },
  },
  methods: {
    ...mapState(['journal_voucher_type']),
    ...mapMutations(['addPane']),
    reset() {
      Object.assign(this.$data, this.$options.data())
      this.searchHandle(1)
    },
    searchHandle(pageNo) {
      this.selectedRows = []
      this.selectedRowKeys = []
      this.$refs.CommonTableRef.queryList(pageNo)
    },
    createHandle() {
      this.$router.push({ name: 'receivableDetail' })
    },
    async getList(params) {
      this.loading = true
      await http({
        url: api.getVoucherLedgerList,
        data: {
          ...this.formData,
          ...params,
          ...this.$route.query,
        },
        success: (res) => {
          let result = convertKeysToCamelCase(res.result)
          this.tableData = result.list.map((item) => {
            this.getShowName(item)
            return item
          })
          this.total = res.result.total
        },
      })
      this.loading = false
    },
    getShowName(data) {
      let dimensionListName = data.dimensionList
        .map((item) => {
          return `${item.accountingDimensionName}: ${item.accountingDimensionValueName}`
        })
        .join(';')
      this.$set(data, 'dimensionListName', dimensionListName)
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
  },
}
</script>
